<template>
  <div id="app">
    <app-preloader v-if="loading" :load="loading" />
    <app-layout v-else>

      <template #image>
        <app-photo :image="cv.photo" :alt="fullName" />
      </template>

      <template #card>
        <app-card v-bind="{ ...cv }" />
      </template>

      <template #aside>
        <app-widget title="About me">
          <app-widget-content :text="cv.about_me" />
        </app-widget>

        <app-widget-group-list :list="cv.skills">
          <template #item="{ title, list }">
            <app-widget :title="title">
              <app-widget-list :items="list" />
            </app-widget>
          </template>
        </app-widget-group-list>

        <app-widget title="Contact me">
          <app-widget-reference :name="fullName" :position="cv.position" :contacts="cv.contacts" />
          <app-card-social class="social--widget" :items="cv.social" />
        </app-widget>
      </template>

      <template #content>
        <app-content-block title="Education">
          <app-content-block-list :items="cv.education" />
        </app-content-block>

        <app-content-block title="Work Experience">
          <app-content-block-list :items="cv.experience">
            <template #additionally="{ stack }">
              <app-content-stack-list
                  v-if="stack && stack.length"
                  :items="stack"
              />
            </template>
          </app-content-block-list>
        </app-content-block>
      </template>

    </app-layout>
  </div>
</template>

<script>
import api from './api';
import AppWidgetGroupList from '@/components/components/app-widget/app-widget-group-list.vue';
import AppContentStackList from "@/components/components/app-content-block/app-content-stack-list.vue";

const CV_PATH = 'cv/front' //front or qa

export default {
  name: 'App',
  components: {AppContentStackList, AppWidgetGroupList },

  data () {
    return {
      cv: {},
      loading: true,
    }
  },

  computed: {
    fullName () {
      const { name: { first, last } } = this.cv;

      return [first, last].join(' ');
    },
  },

  methods: {
    async setDate () {
      try {
        this.cv = await api.resource(CV_PATH);
      } catch(e) {
        console.error(e);
      } finally {
        this.loading = !this.loading
      }
    },
  },

  created () {
    this.setDate();
  },
}
</script>
