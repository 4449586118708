<template>
  <div class="stack-list">
    <div class="stack-list__list">
      <div
          v-for="(item, i) in items"
          :key="`stack-${i}`"
          class="stack-list__item"
          v-text="item"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
.stack-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 10px 0 0;

  &__title {
    font-size: 14px;
    color: $gray-700;
  }

  &__list {
    display: flex;
    gap: 6px;
    font-size: 14px;
    flex-wrap: wrap;
  }

  &__item {
    padding: 4px 8px;
    background: $white;
    border-radius: 4px;
    color: $gray-750;
    border: 1px solid $color-second;
    font-size: 12px;
  }
}
</style>
