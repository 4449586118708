import '@/assets/styles/base/index.scss';

import Vue from 'vue';
import App from './App.vue';

import svgIcon from '@/components/common/svg-icon';
import appPreloader from '@/components/common/app-preloader';
import appLayout from '@/components/app-layout';
import appPhoto from '@/components/components/app-photo';
import appCard from '@/components/components/app-card/app-card';
import appCardContacts from '@/components/components/app-card/app-card-contacts';
import appCardSocial from '@/components/components/app-card/app-card-social';
import AppWidget from '@/components/components/app-widget/app-widget';
import AppWidgetContent from '@/components/components/app-widget/app-widget-content';
import AppWidgetList from '@/components/components/app-widget/app-widget-list';
import AppWidgetGroupList from '@/components/components/app-widget/app-widget-group-list';
import AppWidgetReference from '@/components/components/app-widget/app-widget-reference';
import AppContentBlock from '@/components/components/app-content-block/app-content-block';
import AppContentBlockList from '@/components/components/app-content-block/app-content-block-list';
import AppContentStackList from "@/components/components/app-content-block/app-content-stack-list";

Vue.component('svg-icon', svgIcon);
Vue.component('app-preloader', appPreloader);
Vue.component('app-layout', appLayout);
Vue.component('app-photo', appPhoto);
Vue.component('app-card', appCard);
Vue.component('app-card-contact', appCardContacts);
Vue.component('app-card-social', appCardSocial);
Vue.component('app-widget', AppWidget);
Vue.component('app-widget-content', AppWidgetContent);
Vue.component('app-widget-group-list', AppWidgetGroupList);
Vue.component('app-widget-list', AppWidgetList);
Vue.component('app-widget-reference', AppWidgetReference);
Vue.component('app-content-block', AppContentBlock);
Vue.component('app-content-block-list', AppContentBlockList);
Vue.component('app-content-stack-list', AppContentStackList);

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#app')

